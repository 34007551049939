.pin {
	position: relative;
	width: 30px;
	height: 30px;
	background-color: #f9f9f9;
	border: 3px solid #1677ff;
	border-radius: 50%;
}

.pin::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 25px;
	height: 25px;
	/* background-color: #f00; */
	border-radius: 50%;
	transform: translate(-50%, -50%);
}

.pin span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: black;
	font-size: 10px;
	text-align: center;
}
