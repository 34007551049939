.detalles {
	background-color: #1a2b3c;
}

.detalles__content,
.detalles__container,
.detalles__events {
	width: 100%;
	margin-bottom: 20px;
}

.detalles__nav {
	background-color: #1a2b3c;
	border-bottom: 1px solid #2e4a6b;
	overflow: hidden;
}

.detalles__nav-desktop {
	display: none;
}

.detalles__mobile {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px;
	border-bottom: 1px solid #2e4a6b;
	margin-bottom: 20px;
}

.detalles__texts-1 {
	margin-top: 5px;
	cursor: pointer;
}

.detalles__texts-2 {
	display: flex;
	color: #fff;
}

.detalles__texts-3 {
	display: flex;
	gap: 10px;
}

.detalles__container {
	display: flex;
	width: 100%;
	height: 30vh;
	margin: 0 auto;
	padding: 15px;
	gap: 10px;
	background-color: #fff;
	border-radius: 10px;
}

.detalles2 {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 1;
}

.detalles__title {
	color: #1a2b3c;
	font-size: 24px;
}

.detalles__img {
	width: 100%;
	border-radius: 12px;
	background-color: #2e4a6b;
	box-shadow: 0 6px 17px 0 rgba(76, 110, 217, 0.3);
}

.detalles__content {
	display: none;
}

.reference-list-mobile {
	position: absolute;
	right: 0;
	top: 0%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100vh;
	padding: 45px;
	margin-bottom: 10px;
	border-radius: 5px;
	background-color: #fff;
	cursor: pointer;
	transition: background-color 0.3s, box-shadow 0.3s;
	box-shadow: 0 1px 4px -2px rgba(0, 0, 0, 1.1);
	overflow-y: scroll;
	z-index: 1;
}

.reference-list-mobile h1 {
	font-size: 20px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.detalles__paquete {
	list-style: none;
}

.detalles__code {
	font-size: 14px;
	font-weight: 500;
	color: #1a2b3c;
}

.detalles__from {
	margin-top: 40px;
}

.detalles__subtitle {
	position: absolute;
}

.detalles__subtitle--1 {
	color: #1a2b3c;
	top: 21%;
}

.detalles__subtitle--2 {
	color: #1a2b3c;
	left: 37%;
	top: 21%;
}

.detalles__texts-3 {
	cursor: pointer;
}

.detalles__events {
	width: 100%;
	margin: 0 auto;
	margin-top: 20px;
	padding: 15px 0px;
	background-color: #fff;
	border-radius: 10px;
}

.reference-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 5px;
	height: 450px;
	overflow-y: auto;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.reference-list::-webkit-scrollbar {
	display: none;
}

.reference-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 85%;
	padding: 15px;
	margin-bottom: 10px;
	border-radius: 5px;
	background-color: #fff;
	cursor: pointer;
	transition: background-color 0.3s, box-shadow 0.3s;
	box-shadow: 0 1px 4px -2px rgba(0, 0, 0, 1.1);
}

.reference-item.selected {
	width: 80%;
	background-color: #1a73e8;
	color: white;
}

.reference-item span {
	font-weight: bold;
	margin-bottom: 5px;
	margin-top: 5px;
}

.reference-item p {
	margin: 2px 0;
}

.reference-item img {
	padding: 10px;
	border-radius: 10px;
	margin-left: 5px;
	margin-right: 15px;
	background-color: #2e4a6b;
}

.reference-item.selected img {
	background-color: #fff;
}

@media (min-width: 1024px) {
	.detalles__nav-desktop {
		display: block;
		margin-bottom: 75px;
	}

	.mobile {
		display: none;
	}

	.detalles__container {
		position: relative;
		width: 100%;
		height: 35vh;
		border-radius: 10px;
	}

	.detalles__nav {
		display: flex;
		align-items: center;
		height: 70px;
	}

	.detalles__input {
		width: 45%;
		top: 16px;
		left: 28%;
	}

	.display {
		display: flex;
	}

	.detalles__events {
		width: 100%;
		margin-top: 10px;
		padding: 10px;
		border-radius: 10px;
	}

	.detalles__subtitle--1 {
		top: 37%;
	}

	.detalles__texts {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-left: 60px;
		color: #fff;
	}

	.detalles__nav-container {
		display: flex;
		align-items: center;
	}

	.detalles__logo {
		position: relative;
		right: 20px;
		width: 75px;
	}

	.detalles__navbar {
		position: absolute;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
	}

	.detalles__navbar button {
		outline: none;
		border: none;
		background: none;
		color: #ffffff;
		background-color: #2e4a6b;
		padding: 10px 15px;
		border-radius: 5px;
		cursor: pointer;
	}

	/* .container__lenguajes{
        position: absolute;
        right: 90px;
        top: -9px;
    } */

	.detalles__content-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.detalles__content {
		position: fixed;
		left: 0;
		display: block;
		width: 295px;
		padding: 20px 0px;
		margin-top: -50px;
		border-radius: 0 10px 10px 0;
		background-color: #2e4a6b;
	}

	.detalles__content-2 {
		overflow-y: auto;
		height: 85vh;
		margin-top: -50px;
		margin-left: 15px;

		&::-webkit-scrollbar {
			width: 0;
			background: transparent;
		}

		scrollbar-width: none;
		-ms-overflow-style: none;
	}

	.detalles__content-2::-webkit-scrollbar {
		display: none;
	}

	.detalles__content h1 {
		text-align: center;
		font-size: 17px;
		color: #fff;
	}

	.detalles__subtitle--2 {
		left: 30%;
		top: 37%;
	}
}
