.aside{
    position: absolute;
    right: 0;
    top: 0%;
    width: 100%;
    height: 100vh;
    padding: 55px 30px 150px 36px;
    background-color: #fff;
    z-index: 1;
    
}

.aside__lenguaje{
    border-top: 1px solid #000;
    padding: 50px 0;
}

.aside__btn{
    text-align: center;
    margin-bottom: 20px;
    font-size: 25px;
    cursor: pointer;
}

.toggle-image{
    position: relative;
    top: 25px;
    background-color: darkgrey;
    padding: 5px 5px;
    border-radius: 30px;
}