.section__input {
	max-width: 630px;
	margin: 0 auto;
	padding: 8px 15px;
	background-color: white;
	border-radius: 30px;
	border: 2px solid #2e4a6b;
}

.search-list {
	max-height: 135px;
	padding-right: 10px;
	overflow-y: auto;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.search-list::-webkit-scrollbar {
	display: none;
}

.search-content {
	min-width: 300px;
}

.input-group {
	display: flex;
	align-items: center;
}

.input-number {
	color: #333;
	padding: 8px 12px;
	border-radius: 5px 0 0 5px;
	font-weight: bold;
}

.initial-input {
	position: relative;
	min-width: 300px;
	padding: 10px;
	font-size: 16px;
	border: none;
}

.input {
	flex: 1 1;
	font-size: 16px;
	outline: none;
	padding: 10px 0;
	border: none;
}

.input::placeholder {
	color: grey;
	padding: 10px 0;
}

.remove-btn {
	color: #2e4a6b;
	background-color: white;
	border: none;
	padding: 10px 15px;
	border-radius: 50%;
	cursor: pointer;
	font-size: 20px;
}

.btn {
	position: absolute;
	right: 10px;
	background-color: #2e4a6b;
	color: white;
	border: none;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 20px;
	cursor: pointer;
	z-index: 1;
}

.btn:hover {
	background-color: #4573a0;
}

.validacion {
	width: 50%;
	margin: 0 auto;
	padding: 10px;
	border-radius: 5px;
	font-size: 12px;
	background-color: #fff;
}

.validacion span {
	margin-right: 10px;
	background-color: red;
	border-radius: 10px;
	padding: 1px 5px;
	color: #fff;
}

.search-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #dfdada;
	margin-top: 10px;
	padding: 3px;
	position: sticky;
	bottom: 0;
	z-index: 1;
}

.search-previous {
	position: absolute;
	width: 90%;
	left: 15px;
	background-color: #2e4a6b;
	border-radius: 5px;
	font-size: 0.14rem;
	color: #fff;
	margin-top: 10px;
	padding: 8px 15px;
	cursor: pointer;
}

.previous-reference-btn {
	border: none;
	background: none;
	color: white;
}

.clear-btn {
	border: none;
	background-color: white;
	color: #2e4a6b;
	font-size: 16px;
	cursor: pointer;
}

@media (min-width: 1024px) {
	.search-content {
		min-width: 600px;
	}

	.search-list {
		max-height: 135px;
		padding-right: 10px;
	}

	.initial-input {
		min-width: 600px;
	}

	.search-previous {
		left: 32px;
	}
}
