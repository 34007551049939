.progress {
	position: absolute;
	top: 28%;
}

.progress__container {
	width: 80%;
	margin: 20px auto;
}

.progress__bar {
	width: 345px;
	height: 15px;
	margin-left: -75px;
	border: 1px solid #ccc;
	border-radius: 10px;
	overflow: hidden;
}

.progress-bar-inner {
	height: 100%;
	background-color: #1a2b3c;
	transition: width 0.5s ease-in-out;
}

.process-bar-dot-wrap {
	position: absolute;
	top: 15px;
	height: 23px;
	left: -5px;
	right: 15px;
	padding: 0 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.process-bar-dot-content {
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: 0 2px 6px 0 rgba(0, 58, 151, 0.3);
}

.process-bar-dot {
	background-color: #fff;
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 5px;
}

.process-point {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background-image: linear-gradient(103deg, #1a2b3c 1%, #1a2b3c);
	box-shadow: 0 1px 3px 0 rgba(0, 58, 151, 0.3);
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.process-package-img-content {
	width: 26px;
	height: 26px;
	position: absolute;
	border-radius: 13px;
	top: 6px;
	background-color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0, 58, 151, 0.3);
}

.process-package-icon-normal {
	border: 3px solid #1a2b3c;
	padding: 10px 4px;
	border-radius: 20px;
}

.process-package-img {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 10px;
	height: 10px;
	transform: translate(-50%, -50%);
	transition: left 1s linear;
}

.process-bar-pointname-normal {
	font-size: 11px;
	color: #1a2b3c;
	line-height: 60px;
	max-width: 60px;
	font-weight: 600;
	display: inline-block;
	text-align: left;
	transform: translateX(calc(10px - 50%));
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@media (min-width: 1024px) {
	.progress {
		text-align: center;
		position: absolute;
		top: 60%;
		right: 17%;
	}
}
