header{
    width: 95%;
    margin: 0 auto;
}


.navbar{
    display: flex;
}


.desktop{
    display: none;
}

.figure__navbar img{
    width: 25%;
}

.hamburguer{
    position: relative;
    top: 25px;
    background-color: darkgrey;
    padding: 5px 5px;
    border-radius: 30px;
}

.close{
    position: absolute;
    right: 0;
    padding: 20px 40px;
    z-index: 2;
}


@media (min-width:1024px){
    header{
        width: 80%;
    }

    .mobile{
        display: none;
    }

    .desktop{
        display: block;
        margin-bottom: 40px;
    }

    .desktop__container{
        width: 88vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }

    .desktop__texts{
        display: flex;
        gap: 10px;
    }
    .desktop__texts h1{
        color: white;
    }

    .container__navbar{
        display: flex;
        align-items: center;
    }

    .logo{
        width: 100px;
    }
}